<template>
    <div class="max_cont_width flex_box" @mousedown="handleonmousedown($event)">
        <div class="left_menu" v-loading="loadig">
            <button class="btn_Retr" @click="RetrievalResult">
                <img src="../../assets/img/search/btn_ic1.png" />
                Apply Filters
            </button>
            <div class="search_List_box">
                <div class="search_List_title">Search Options</div>
                <div class="coll_search">
                    <input type="text" v-model="hscode" @change="getConditions(true)" class="coll_search_inpt" placeholder="Select HS Code" />
                    <img class="coll_search_btn" src="../../assets/img/head_search_ic.png" @click="getConditions(true)" />
                </div>
                <div class="coll_search">
                    <input type="text" v-model="buyName" class="coll_search_inpt" placeholder="Enter Buyer Name" />
                    <img class="coll_search_btn" src="../../assets/img/head_search_ic.png" @click="getConditions" />
                </div>
                <div class="coll_search">
                    <input type="text" v-model="supplerName" class="coll_search_inpt" placeholder="Enter Supplier Name" />
                    <img class="coll_search_btn" src="../../assets/img/head_search_ic.png" @click="getConditions" />
                </div>
                <div class="coll_search">
                    <input type="text" v-model="searchProduct" class="coll_search_inpt" placeholder="Enter Commodity Description" />
                    <img class="coll_search_btn" src="../../assets/img/head_search_ic.png" @click="getConditions" />
                </div>
            </div>
            <el-collapse v-model="openMenu" @change="zhankai()">
                <el-collapse-item title="Products" name="5" v-if="hscodeList.length > 0">
                    <template slot="title">
                        Products
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="cas_loc" :class="tabDisabled2 ? 'Products' : ''" ref="input">
                        <el-cascader ref="Products " clearable placeholder="Select Products" filterable :key="cascaderKey" v-model="hscodeCheckList" @change="menuCheck('hscode')" :options="hscodeList" :props="optionsProps" collapse-tags popper-class="cas_loc_box">
                            <template slot-scope="{ data }">
                                <!-- <span class="casca_lab">{{ data.label }}</span> -->
                                <el-tooltip class="casca_lab" effect="dark" :content="data.valueEn" placement="top-start">
                                    <!-- <div  @mouseover="onMouseOver(child.name,i)" class="line_1 coll_child_name" ><b ref="str">{{ child.name }}</b></div> -->
                                    <span class="casca_lab">
                                        <span ref="sty">{{ data.valueEn }}</span>
                                    </span>
                                </el-tooltip>
                                <!-- <span class="casca_count">({{ data.count }})</span> -->
                                <el-tooltip class="coll_child_num line_1" effect="dark" :content="data.dateCount | FilterNum" placement="top-start">
                                    <span class="coll_child_num line_1">{{ data.dateCount | FilterNum }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Country" name="2">
                    <template slot="title">
                        Country
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="cas_loc">
                        <el-cascader ref="Country" clearable placeholder="Select Country" :filter-method="filterCasCountry" :key="cascaderKey" filterable v-model="countryCheckList" @change="menuCheck('country')" :options="countryList" :props="{ multiple: false }" collapse-tags popper-class="cas_loc_box">
                            <template slot-scope="{ data }">
                                <!-- <span class="casca_lab">{{ data.label }}</span> -->
                                <el-tooltip class="casca_lab" effect="dark" :content="data.label" placement="top-start">
                                    <!-- <div  @mouseover="onMouseOver(child.name,i)" class="line_1 coll_child_name" ><b ref="str">{{ child.name }}</b></div> -->
                                    <span class="casca_lab">
                                        <span ref="sty">{{ data.label }}</span>
                                    </span>
                                </el-tooltip>
                                <!-- <span class="casca_count">({{ data.count }})</span> -->
                                <el-tooltip class="coll_child_num line_1" effect="dark" :content="data.count | FilterNum" placement="top-start">
                                    <span class="coll_child_num line_1">{{ data.count | FilterNum }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Markets" name="1">
                    <template slot="title">
                        Markets
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="marketCheckList" @change="menuCheck('market')">
                            <el-checkbox v-for="(child, i) in marketList" :key="i" :value="child.valueEn" :label="child.code">
                                <span class="line_1 coll_child_name">{{ child.valueEn }}</span>
                                <span class="line_1 coll_child_num">{{ child.dateCount | FilterNum }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>

                <el-collapse-item title="Import & Export" name="3">
                    <template slot="title">
                        Import & Export
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="importOrExportCheckList" @change="menuCheck('importOrExport')">
                            <el-checkbox v-for="(child, i) in importOrExportList" :key="i" :value="child.valueEn" :label="child.code">
                                <span class="line_1 coll_child_name">{{ child.valueEn }}</span>
                                <span class="line_1 coll_child_num">{{ child.dateCount | FilterNum }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Time Period" name="4">
                    <template slot="title">
                        Time Period
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="timePeriodCheckList" @change="menuCheck('timePeriod')">
                            <el-checkbox v-for="(child, i) in timePeriodList" :key="i" :value="child.valueEn" :label="child.code">
                                <span class="line_1 coll_child_name">{{ child.valueEn }}</span>
                                <span class="line_1 coll_child_num">{{ child.dateCount | FilterNum }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="right_box">
            <div class="top_condition flex_center_between_box">
                <div class="condition_lab_box">
                    <div :class="{ condition_labb: true, condition_lab: isActive }" @click="tabshow">
                        <template>
                            <el-tag v-if="hscode" @close="handleClose(hscode, 'hsCode')" closable>{{ hscode }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-if="buyName" @close="handleClose(buyName, 'buyName')" closable>{{ buyName }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-if="supplerName" @close="handleClose(supplerName, 'supplerName')" closable>{{ supplerName }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-if="searchProduct" @close="handleClose(searchProduct, 'searchProduct')" closable>{{ searchProduct }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in marketTag" :key="tag.code + '_marketTag'" @close="handleClose(tag, 'marketTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in countryTag" :key="tag" @close="handleClose(tag, 'countryTag')" closable>{{ tag }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in importTag" :key="tag.code + '_importTag'" @close="handleClose(tag, 'importTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in timeTag" :key="tag.code + '_timeTag'" @close="handleClose(tag, 'timeTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in hscodeTag" :key="tag.code + '_hscode'" @close="handleClose(tag, 'hscodeTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <span class="clear_lab" @click="clearAllTags">Clear All</span>
                    </div>
                </div>

                <div class="flex_center">
                    <!-- <div class="top_ic_btn no_login" v-show="tabActiveName != 'Markets' && tabActiveName != 'Countries' ? true : false" @click="showTranslate = !showTranslate">
                        <img src="../../assets/img/detail/head_ic1.png" class="no_login" />
                    </div> -->
                    <div class="btn_export flex_center" v-show="tabActiveName == 'SearchResults' ? true : false" @click="exportResult">
                        <img src="../../assets/img/search/export_ic1.png" />
                        Export
                    </div>
                </div>
            </div>

            <div class="top_tab">
                <el-tabs v-loading="loading" v-model="tabActiveName" type="card" class="tab_box" @tab-click="handleTabClick">
                    <el-tab-pane label="Search Results" name="SearchResults" class="result_tab">
                        <div class="table_box">
                            <div class="tbl_search_box" v-show="isNameSearch">
                                <div class="tbl_search">
                                    <el-input clearable v-model="searchProduct" type="text" placeholder="Products Name" @change="getTradeByProduct()" />
                                    <img src="../../assets/img/detail/search_ic1.png" @click="getTradeByProduct()" />
                                </div>
                            </div>
                            <el-table ref="treeTable" :data="searchResultsTable" border style="width: 100%">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">
                                    <noDate />
                                </template>
                                <el-table-column label="Details" prop="" align="center" width="80" :resizable="false">
                                    <template slot-scope="scope">
                                        <div class="table_oper1" @click="godetail(scope.row)">
                                            <img src="../../assets/img/xiangqingbeifen.png" />
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Date" prop="ie_date" width="85" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Products" min-width="143" prop="hscode_description_inter,hscode_description_native" align="center" show-overflow-tooltip :resizable="false">
                                    <!--  <template slot="header">
                                        <div class="table_add" @click="searchProducts()">
                                            Products
                                            <img src="../../assets/img/detail/search_ic1.png" />
                                        </div>
                                    </template>-->
                                    <template slot-scope="scope">
                                        <span v-show="!showTranslate">{{ scope.row.hscode_description_inter }}</span>
                                        <!-- <span v-show="showTranslate">{{ scope.row.hscode_description_native }}</span> -->
                                    </template>
                                </el-table-column>
                                <el-table-column label="Buyer Names" min-width="143" prop="import_name" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <span class="tbl_company1 line_11" @click="setid(scope.row, 'Buyer')">{{ scope.row.import_name_cn }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Destination" prop="import_country_native,import_country_inter" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <div @click="gotocountry1(scope.row, 'import')" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; color: #1290c9; text-decoration: underline; cursor: pointer" v-show="!showTranslate">{{ scope.row.import_country_inter }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Supplier Names" min-width="143" prop="export_name_cn" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <span class="tbl_company1 line_11" @click="setid(scope.row, 'Supplier')">{{ scope.row.export_name_cn }}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column label="Origin " prop="export_country_native,export_country_inter" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <div @click="gotocountry1(scope.row, 'export')" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; color: #1290c9; text-decoration: underline; cursor: pointer" v-show="!showTranslate">{{ scope.row.export_country_inter }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Amount" prop="total_count" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        {{ scope.row.amount | FilterNum }}
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="page_i_box">
                                <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ searchName }}"</div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Markets" name="Markets" :disabled="tabDisabled && disabled3" class="market_box Markets">
                        <el-tooltip slot="label" effect="dark" v-if="tabDisabled" content="Please select a particular product in the left panel" placement="top-start">
                            <span @click="aaaa()" class="tmp">{{ 'Markets' }}</span>
                        </el-tooltip>

                        <div class="table_market_box market_tbl">
                            <el-table ref="marketTable" :data="marketTable" border style="width: 100%; height: 540px">
                                <!-- no data -->
                                <template slot="empty">No data</template>
                                <el-table-column label="#" align="center" :resizable="false" type="index" width="50" />
                                <el-table-column label="Date" prop="date" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="No. of Buyer" prop="buyer" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.buyer | FilterNum }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="No. of Supplier" prop="supplier" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.supplier | FilterNum }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Transaction" prop="transcations" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.transcations | FilterNum }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="echart_box">
                            <div class="right_cont_box">
                                <div class="echart_tab">
                                    <el-tabs v-model="marketActiveName" type="card" @tab-click="handleMarketClick">
                                        <el-tab-pane label="Buyer" name="market_Buyer">
                                            <div ref="marketBuyer" id="marketBuyer" class="echart_cont"></div>
                                        </el-tab-pane>
                                        <el-tab-pane label="Supplier" name="market_Supplier">
                                            <div ref="marketSupplier" id="marketSupplier" class="echart_cont"></div>
                                        </el-tab-pane>
                                        <el-tab-pane label="Transaction" name="market_Transaction">
                                            <div ref="marketTransaction" id="marketTransaction" class="echart_cont"></div>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Countries" name="Countries" :disabled="tabDisabled" class="market_box">
                        <el-tooltip slot="label" effect="dark" v-if="tabDisabled" content="Please select a particular product in the left panel" placement="top-start">
                            <span @click="aaaa()" class="tmp">{{ 'Countries' }}</span>
                        </el-tooltip>
                        <div class="table_market_box market_tbl">
                            <el-table ref="countryTable" :data="countryTable" border style="width: 100%; height: 540px">
                                <!-- no data -->
                                <template slot="empty">No data</template>
                                <el-table-column label="#" align="center" :resizable="false" type="index" width="50" />
                                <el-table-column label="Country" prop="countryEn" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <span @click="gotocountry(scope.row)">{{ scope.row.countryEn }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="No. of Buyer" prop="buyer" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.buyer | FilterNum }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="No. of Supplier" prop="supplier" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.supplier | FilterNum }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Transaction" prop="transcations" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.transcations | FilterNum }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="echart_box">
                            <div class="right_cont_box">
                                <div class="echart_tab">
                                    <el-tabs v-model="countryActiveName" type="card" @tab-click="handleCountriesClick">
                                        <el-tab-pane label="Buyer" name="countries_Buyer">
                                            <div ref="countriesBuyer" id="countriesBuyer" class="echart_cont"></div>
                                        </el-tab-pane>
                                        <el-tab-pane label="Supplier" name="countries_Supplier">
                                            <div ref="countriesSupplier" id="countriesSupplier" class="echart_cont"></div>
                                        </el-tab-pane>
                                        <el-tab-pane label="Transaction" name="countries_Transaction">
                                            <div ref="countriesTransaction" id="countriesTransaction" class="echart_cont"></div>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Buyers" name="Buyers" :disabled="tabDisabled" class="market_box">
                        <el-tooltip slot="label" effect="dark" v-if="tabDisabled" content="Please select a particular product in the left panel" placement="top-start">
                            <span @click="aaaa()" class="tmp">{{ 'Buyers' }}</span>
                        </el-tooltip>
                        <div class="table_market_box market_tbl">
                            <el-table ref="buyTable" :data="buyTable" border style="width: 100%">
                                <!-- no data -->
                                <template slot="empty">No data</template>
                                <el-table-column label="#" align="center" :resizable="false" type="index" width="50" />
                                <el-table-column label="Buyer Name" prop="companyNameEn" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <span @click="setid1(scope.row)">{{ scope.row.companyNameEn }}</span>
                                        <!-- <router-link :to="{ path: '/country', query: { companyCountry: scope.row.countryCode } }">{{ scope.row.companyNameEn }}</router-link> -->
                                    </template>
                                </el-table-column>
                                <el-table-column label="Country" prop="countryEn" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <span @click="gotocountry(scope.row)">{{ scope.row.countryEn }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Transaction" prop="transcations" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.transcations | FilterNum }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="echart_box">
                            <div class="right_cont_box">
                                <div ref="Buyers" id="Buyers" style="width: 520px; height: 575px"></div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Suppliers" name="Suppliers" :disabled="tabDisabled" class="market_box">
                        <el-tooltip slot="label" effect="dark" v-if="tabDisabled" content="Please select a particular product in the left panel" placement="top-start">
                            <span @click="aaaa()" class="tmp">{{ 'Suppliers' }}</span>
                        </el-tooltip>
                        <div class="table_market_box market_tbl">
                            <el-table ref="supplierTable" :data="supplierTable" border style="width: 100%">
                                <!-- no data -->
                                <template slot="empty">No data</template>
                                <el-table-column label="#" align="center" :resizable="false" type="index" width="50" />
                                <el-table-column label="Supplier Name" prop="companyNameEn" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <span @click="setid1(scope.row)">{{ scope.row.companyNameEn }}</span>
                                        <!-- <router-link :to="{ path: '/country', query: { companyCountry: scope.row.countryCode } }">{{ scope.row.companyNameEn }}</router-link> -->
                                    </template>
                                </el-table-column>
                                <el-table-column label="Country" prop="countryEn" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <span @click="gotocountry(scope.row)">{{ scope.row.countryEn }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Transaction" prop="transcations" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.transcations | FilterNum }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="echart_box">
                            <div class="right_cont_box">
                                <div ref="Suppliers" id="Suppliers" style="width: 520px; height: 575px"></div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>

        <!-- 点击导入 -->
        <el-dialog :visible.sync="exportDialog"  center :close-on-click-modal="false" custom-class="large_dialog" :append-to-body="true">
            <div class="large_detail_cont" v-loading="exportLoding">
                <div class="dialog_detail_title">Please confirm your export infromation</div>
                <div class="company_box">
                    <div class="company-head"> Filter Information:</div>
                    <div >
                        <ul class="company-body"> 
                            <li class="company-item condition_labb">
                                <div style="width:110px">  HS Code :    </div> 
                                <el-tag v-if="hscode"  >{{ hscode }}</el-tag>
                            </li>
                            <li class="company-item condition_labb">  
                                <div style="width:105px">   Total Records :  </div> 
                                <span   >{{ page.total | FilterNum }}</span>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width:110px">   Buyer Name :   </div> 
                        
                                <el-tag v-if="buyName"  >{{ buyName }}</el-tag>
                            </li>
                         
                            <li class="company-item condition_labb">
                               
                                <div style="width:155px">  Commodity Description : </div> 
                                <el-tag v-if="searchProduct"  >{{ searchProduct }}</el-tag>
                            </li>
                            <li class="company-item condition_labb">
                            <div style="width:110px"> Supplier Name : </div> 
                                  <div style="display:flex"></div>
                                <el-tag v-if="supplerName"  >{{ supplerName }}</el-tag>
                            </li>
                          
                       
                            <li class="company-item condition_labb">
                                <div style="width:60px">Country :   </div> 
                      
                                <el-tag v-for="tag in countryTag" :key="tag" >{{ tag }}</el-tag>
                            </li>
                           
                            <li class="company-item condition_labb">
                                <div style="width:110px">Import & Export :  </div>  
                                <div style="display:flex">
                                    <el-tag v-for="(tag,index) in importTag" :key="tag.code + '_importTag'" >{{ tag.valueEn }}<i v-if="index+1!=importTag.length">,</i></el-tag>
                                </div>
                                  
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width:60px">Markets :   </div>  
                                    <el-tag v-for="(tag,index) in marketTag" :key="tag.code + '_marketTag'" >{{ tag.valueEn }}<i v-if="index+1!=marketTag.length">,</i></el-tag>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width:110px">Time Period :    </div>  
                                <div style="display:flex">
                                    <el-tag v-for="tag in timeTag" :key="tag.code + '_timeTag'" >{{ tag.valueEn }}</el-tag></div>
                          
                            </li>

                            <li class="company-item condition_labb">
                                <div style="width:60px">Products:    </div>  
                                <el-tag v-for="tag in hscodeTag" :key="tag.code + '_hscode'">{{ tag.valueEn }}</el-tag>
                            </li>
                        </ul>
                    </div>

                     
                    <!-- <div class="comp_top_check flex_center_between_box">
                        <el-checkbox v-model="checkAll" @change="checkedAllCompany">Select All</el-checkbox>
                        <div class="comp_search">
                            <input class="coll_search_inpt" v-model="filterName" type="text" placeholder="Enter product name" @keyup.enter="hanldFilterName()" />
                            <img class="coll_search_btn" src="../../assets/img/head_search_ic.png" @click="hanldFilterName" />
                        </div>
                    </div>
                    <div class="check_item" v-infinite-scroll="infiniteScroll" v-if="exportDialog" :infinite-scroll-disabled="routeLoad || noMore" :infinite-scroll-distance="1">
                        <el-checkbox-group v-model="checkedCompanyList">
                            <el-checkbox v-for="(company, index) in companyList" :label="company" :key="index">{{ company.hscode_description_inter }}</el-checkbox>
                        </el-checkbox-group>
                    </div> -->
                </div>
                <div class="company-head">Data Information :</div>
                <div style="color: #333333;margin-left:15px">
                    The maximum you can export is <span style="color:red">500</span> records. Should you need to export entire records, please click "Request Data Service" <br>for further assistance.
                </div>
                <div class="btn_comp_box" >
                    <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="json_fields"  name="Trade.xls" :export-config="exportConfig">
                        <button class="btn_export" @click="exportCompany">Export(Max500)</button>
                    </download-excel>
                    <div class="btn_export flex_center" style="margin-left:20px;width: 160px;">
                           
                           <a href="mailto:request@csibizinfo.com" style="color: #FFFFFF;">Request Data Service</a>
                       </div>
                </div>
     
            </div>
        </el-dialog>
        <!--点击进出口信息详情 -->
        <el-dialog :visible.sync="imexportsDialog" center :close-on-click-modal="false" custom-class="large_dialog" :append-to-body="true">
            <div class="large_detail_cont trade-details-modal"   id="exportDetail">
                <!-- <div class="dialog_detail_title" style="margin-bottom: 0;">Trade Transaction Detail</div> -->
                <div class="ant-modal-body">
                    <div class="trade-details-field">
                        <table>
                            <!-- <thead>
                                <tr>
                                    <th colspan="4" style="color: rgb(102, 102, 102); text-align: right; font-weight: 400">
                                        Data sources：
                                        <span style="font-weight: 400">Imported from the United States</span>
                                    </th>
                                </tr>
                            </thead> -->
                            <tbody>
                                <tr style="height: 24px">
                                    <th style="width: 15%">Date:</th>
                                    <td style="width: 35%">
                                        {{ value?.imp_year }}
                                        <span v-if="value?.imp_month">-</span>
                                        {{ value?.imp_month }}
                                        <span v-if="value?.imp_day">-</span>
                                        {{ value?.imp_day ? value?.imp_day : '15' }}
                                    </td>
                                    <th style="width: 15%">Master Bill Of Lading:</th>
                                    <td style="width: 35%">{{ value?.master_bill_of_lading }}</td>
                                </tr>
                                <tr style="height: 24px" class="back">
                                    <th style="width: 15%">Source:</th>
                                    <td style="width: 35%">
                                        {{ value?.channel_no }}_
                                        <span v-if="value?.iesign == 'i'">IMPORT</span>
                                        <span v-if="value?.iesign == 'e'">EXPORT</span>
                                    </td>

                                    <th style="width: 15%">Bill No.:</th>
                                    <td style="width: 35%">
                                        {{ value?.source_id }}
                                        <span v-if="value?.source_id_type">({{value?.source_id_type}})</span>
                                    
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <table>
                            <thead>
                                <tr><th colspan="4">商品描述</th></tr>
                            </thead>
                            <tbody>
                                <tr style="height: 24px">
                                    <th style="width: 15%">HS编码</th>
                                    <td style="width: 35%"><span>--</span></td>
                                    <th style="width: 15%"></th>
                                    <td style="width: 35%"></td>
                                </tr>
                                <tr>
                                    <th style="width: 15%">商品描述</th>
                                    <td colspan="3">CHRISTMAS DECORATION PER PO NO. 0010382, 0010387, 0010395 AINA COMPONENTS HS CODE: 950510 11" CLASSIC ELF - APPLE GRN 11" CLASSIC ELF - FOREST GRN 11" CLASSIC ELF - RED SET OF THREE CHRISTMAS ELVES 9IN JOLLY MRS. SANTA 8IN JOLLY REINDEER 5IN JOLLY GREEN ELF 5IN BOY MOUSE 6IN JOLLY GIRL MOUSE 6IN JOLLY SNOWBALL MOUSE 6IN PLAID PINE GIRL MOUSE</td>
                                </tr>
                                <tr style="height: 24px">
                                    <th style="width: 15%">唛头</th>
                                    <td style="width: 35%">ANNALEE (IN DIA) MADE IN CHINA PO QTY:</td>
                                    <th style="width: 15%"></th>
                                    <td style="width: 35%"></td>
                                </tr>
                            </tbody>
                        </table> -->
                        <table>
                            <thead>
                                <tr><th colspan="4">Importer/Exporter</th></tr>
                            </thead>
                            <tbody>
                                <tr style="height: 24px">
                                    <th style="width: 15%"></th>
                                    <td style="width: 35%">Importer/Buyer</td>
                                    <th style="width: 15%"></th>
                                    <td style="width: 35%">Exporter/Seller</td>
                                </tr>
                                <tr style="height: 24px" class="back">
                                    <th style="width: 15%">Name:</th>
                                    <td style="width: 35%" @click="tradetodetail(value?.importer_aaaid, value?.importer_country)">
                                        <span style="text-decoration: underline; color: rgb(18, 144, 201); cursor: pointer">{{ value?.importer_name }}</span>
                                    </td>
                                    <th style="width: 15%"></th>
                                    <td style="width: 35%" @click="tradetodetail(value?.supplier_aaaid, value?.supplier_country)">
                                        <span style="text-decoration: underline; color: rgb(18, 144, 201); cursor: pointer">{{ value?.supplier_name }}</span>
                                    </td>
                                </tr>
                                <tr style="height: 24px">
                                    <!-- <th style="width: 15%">Importer Name</th> -->
                                    <th style="width: 15%">Address:</th>
                                    <td style="width: 35%">{{ value?.importer_address }}</td>
                                    <th style="width: 15%"></th>
                                    <td style="width: 35%">{{ value?.supplier_address }}</td>
                                    <!-- <td colspan="3">
                                        <div class="button-list-in-trade-detail">
                                            <span class="row-content supplier"  @click="tradetodetail(value?.importer_aaaid,value?.importer_country)" style="max-width: 50%;text-decoration: underline;color: #1290C9;cursor: pointer;">{{value?.importer_name}}</span>
                        
                                        </div>
                                    </td> -->
                                    <!-- <th style="width: 15%">Importer Name</th> -->
                                </tr>
                                <tr style="height: 24px" class="back">
                                    <th style="width: 15%">Country:</th>
                                    <td style="width: 35%">
                                        <img v-if="value?.importer_country" class="companyimg" @click="gotocountry2(value?.importer_country)" :src="require('../../../static' + getCountryImgUrl(value?.importer_country))" />
                                        <span style="text-decoration: underline; color: rgb(18, 144, 201); cursor: pointer" @click="gotocountry2(value?.importer_country)">{{ getCountry(value?.importer_country) }}</span>
                                    </td>
                                    <th style="width: 15%"></th>
                                    <td style="width: 35%">
                                        <img v-if="value?.supplier_country" class="companyimg" @click="gotocountry2(value?.supplier_country)" :src="require('../../../static' + getCountryImgUrl(value?.supplier_country))" />
                                        <span style="text-decoration: underline; color: rgb(18, 144, 201); cursor: pointer" @click="gotocountry2(value?.supplier_country)">{{ getCountry(value?.supplier_country) }}</span>
                                    </td>
                                </tr>
                                <tr style="height: 24px">
                                    <th style="width: 15%">Contact:</th>
                                    <td style="width: 35%">{{ value?.importer_contact }}</td>
                                    <th style="width: 15%"></th>
                                    <td style="width: 35%">{{ value?.supplier_contact }}</td>
                                </tr>
                                <tr style="height: 24px" class="back">
                                    <th style="width: 15%">Email:</th>
                                    <td style="width: 35%">{{ value?.importer_email }}</td>
                                    <th style="width: 15%"></th>
                                    <td style="width: 35%">{{ value?.supplier_email }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- 货物信息 -->
                        <table>
                            <thead>
                                <tr><th colspan="4">Cargo Info</th></tr>
                            </thead>
                            <tbody>
                                <tr style="height: 24px">
                                    <th style="width: 15%">HS Code:</th>
                                    <td style="width: 35%">{{ value?.hs_code }}</td>
                                    <th style="width: 15%">HS Description:</th>
                                    <td style="width: 35%;">
                                        {{ value?.hs_code_description }}
                                    </td>
                                    <!-- <td style="width: 35%" v-html="value?.commodity_description"></td> -->

                                </tr>
                                <tr>
                                    <th style="width: 15%" class="back">Product Description:</th>
                                    <td colspan="3" class="back">
                                    <div  v-if="!isExpanded" @click="toggleExpanded" v-html="limitedDescription"></div>
                                        <div  v-else @click="toggleExpanded"  v-html="value?.commodity_description"></div>
                                        <span style="float: right;color: #1290c9;cursor: pointer;text-decoration: underline;" v-if="value?.commodity_description?.length > maxCharacters" @click="toggleExpanded">{{ isExpanded ? 'Retract' : 'Expand All Content' }}</span>
                                    </td>
                                    </tr>
                                <tr style="height: 24px">
                                    <th style="width: 15%">Weight:</th>
                                    <td style="width: 35%">{{ value?.weight }}</td>
                                    <th style="width: 15%">Weight Unit:</th>
                                    <td style="width: 35%">{{ value?.weight_unit }}</td>
                                </tr>
                                <tr style="height: 24px" class="back">
                                    <th style="width: 15%">Quantity:</th>
                                    <td style="width: 35%">{{ value?.quantity }}</td>
                                    <th style="width: 15%">Quantity Unit:</th>
                                    <td style="width: 35%">{{ value?.quantity_unit }}</td>
                                </tr>
                                <tr style="height: 24px">
                                    <th style="width: 15%">Amount:</th>
                                    <td style="width: 35%" v-if="value?.fob_usd != null">{{ value?.fob_usd }}</td>
                                    <td style="width: 35%" v-else>{{ value?.other_currency }}{{ value?.fob_oc }}</td>
                                    <th style="width: 15%">Incoterms:</th>
                                    <td style="width: 35%" v-if="value?.value_type == 'CIF' || value?.value_type == 'FOB'">{{ value?.value_type }}</td>
                                    <td style="width: 35%" v-else></td>
                                </tr>
                            </tbody>
                        </table>
         
                        <table>
                            <thead>
                                <tr><th colspan="4">Shipping Info</th></tr>
                            </thead>
                            <tbody>
                                <tr style="height: 24px">
                                    <th style="width: 15%">Vessel:</th>
                                    <td style="width: 35%">{{ value?.vessel }}</td>
                                    <th style="width: 15%">Voyage:</th>
                                    <td style="width: 35%">{{ value?.voyage }}</td>
                                </tr>
                                <tr style="height: 24px" class="back">
                                    <th style="width: 15%">Country of Origin:</th>
                                    <td style="width: 35%">
                                        <img v-if="value?.origin_country" class="companyimg" :src="require('../../../static' + getCountryImgUrl(value?.origin_country))" @click="gotocountry2(value?.origin_country)" />
                                        <span style="text-decoration: underline; color: rgb(18, 144, 201); cursor: pointer" @click="gotocountry2(value?.origin_country)">{{ getCountry(value?.origin_country) }}</span>
                                    </td>
                                
                                     <th style="width: 15%">Address Of Origin:</th>
                                    <td style="width: 35%">{{ value?.poe }}</td>
                                </tr>
               
                                <tr style="height: 24px">
                                    <th style="width: 15%">Country Of Destnation:</th>
                                    <td style="width: 35%">{{ value?.dest_country }}</td>
                                       <th style="width: 15%">Address Of Destnation</th>
                                    <td style="width: 35%">{{ value?.poa }}</td>
                                </tr>
                                <tr style="height: 24px" class="back">
                                    <th style="width: 15%">Shipper:</th>
                                    <td style="width: 35%">{{ value?.supplier_name }}</td>
                                    <th style="width: 15%">Means Of Transport:</th>
                                    <td style="width: 35%">{{ value?.trans_type }}</td>
                                </tr>
                                <tr style="height: 24px">
                                    <th style="width: 15%">Consignee:</th>
                                    <td style="width: 35%">{{ value?.importer_name }}</td>
                                    <th style="width: 15%">Notifier:</th>
                                    <td style="width: 35%">{{ value?.notify_party_name }}</td>
                                </tr>

                                <!-- <tr style="height: 24px">
                                    <th style="width: 15%">Destination country</th>
                                    <td style="width: 35%">{{ value?.poa_country }}</td>
                                    <th style="width: 15%">Destination</th>
                                    <td style="width: 35%">{{ value?.poa }}</td>
                                </tr>
                                <tr style="height: 24px">
                                    <th style="width: 15%">Various order numbers</th>
                                    <td style="width: 35%">{{ value?.source_id }}</td>
                                    <th style="width: 15%">Order number type</th>
                                    <td style="width: 35%">{{ value?.source_id_type }}</td>
                                </tr>
                                <tr style="height: 24px">
                                    <th style="width: 15%">Transport type</th>
                                    <td style="width: 35%">{{ value?.trans_type }}</td>
                               
                                </tr> -->
                            </tbody>
                        </table>

                        <!-- 金额信息 -->
                        <table>
                            <thead>
                                <tr><th colspan="4">Container</th></tr>
                            </thead>
                            <tbody>
                                <tr style="height: 24px">
                                    <th style="width: 15%">Container ID:</th>
                                    <td style="width: 35%; word-break: break-all">{{ value?.container_id }}</td>
                                    <th style="width: 15%">Container Type:</th>
                                    <td style="width: 35%; word-break: break-all">{{ value?.container_type }}</td>
                                </tr>
                                <tr style="height: 24px" class="back">
                                    <th style="width: 15%">Container Size:</th>
                                    <td style="width: 35%; word-break: break-all">{{ value?.container_size }}</td>
                                    <th style="width: 15%">Container Desc Dode:</th>
                                    <td style="width: 35%; word-break: break-all">{{ value?.container_desc_code }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
           
            </div>
            <div class="flex_center" style="justify-content: right;">
               
                    <div class="btn_export flex_center" @click="exportDetail">
                        <img src="../../assets/img/search/export_ic1.png" />
                        Export
                    </div>
                </div>
        </el-dialog>
    </div>
</template>
<script>
import { searchTrade, getFilterConditions, getTradeAggre, tradedetails } from '@/api/searchApi.js';
import { getCompanyDetailMenuList, ordersAdd,exportTradeDetail } from '../../api/companyApi';
import noDate from '@/components/tableNoDate';
import Page from '@/components/page';
import { mixins1 } from '@/mixins/index';
import bus from '@/components/bus';
import html2pdf from 'html2pdf.js'
export default {
    mixins: [mixins1],
    components: { noDate, Page },
    data() {
        return {
            hscodeListtable: [],
            searchProductname: '', //筛选prouctname
            loading: true,
            isActive: false,
            cascaderKey: 0,
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            searchName: '',
            loadig: true,
            searchCode: '',
            isNameSearch: false,
            disabled3: true,
            searchProduct: '',
            showTranslate: false,
            tabDisabled2: false,
            tabDisabled: true,
            checkAll: false,
            exportDialog: false,
            checkedCompanyList: [],
            companyList: [],
            companyTblList: [],
            noMore: false, // 控制滚动禁用
            routeLoad: false, // 控制滚动禁用
            filterName: '',
            tabActiveName: 'SearchResults', //父级tab
            marketActiveName: 'market_Buyer', //echart tab
            countryActiveName: 'countries_Buyer',
            searchResultsTable: [],
            marketTable: [],
            countryTable: [],
            buyTable: [],
            supplierTable: [],
            echartsBox: '', //echart容器
            marketBuyerList: [],
            marketBuyerX: [],
            marketSupplierList: [],
            marketSupplierX: [],
            marketTranList: [],
            marketTranX: [],
            counBuyerList: [],
            counBuyerX: [],
            counSupplierList: [],
            counSupplierX: [],
            counTranList: [],
            counTranX: [],
            BuyersXList: [],
            BuyersYList: [],
            SuppliersXList: [],
            SuppliersYList: [],
            hscode: '',
            buyName: '',
            supplerName: '',
            filterCountry: '',
            openMenu: [],
            countryList: [],
            countryCheckList: [],
            countryTag: [],
            countryCheck: '',
            province: [],

            marketList: [],
            marketCheckList: [],
            marketTag: [],
            marketCheck: '',

            importOrExportList: [],
            importOrExportCheckList: [],
            importTag: [],
            importOrExportCheck: '',

            timePeriodList: [],
            timePeriodCheckList: [],
            timeTag: [],
            timePeriodCheck: '',

            hscodeList: [],
            hscodeCheckList: [],
            hscodeTag: [],
            hscodeCheck: [],
            hscodeValEnCheck: '',
            payProCodeList: [],
            payProNameList: [],
            optionsProps: {
                label: 'valueEn', //名称展示
                value: 'code', //值展示
                // expandTrigger: 'click', //次级菜单的展开方式
            },
            token: localStorage.getItem('token'),
            powerResult: [],
            // 下载
            title: '',
            json_fields: {
                Date: 'data',
                'Master Bill Of Lading': 'master_bill_of_lading',
                'Source': 'Source',
                'Bill No.': 'source_id',
                'Importer/Buyerr Name': 'importer_name',
                'Importer/Buyer Country': 'importer_country',
                'Importer/Buyer Contact': 'importer_contact',
                'Importer/Buyer Email': 'importer_email',
                'Importer/Buyer Address': 'importer_address',
                'Exporter/Seller Name': 'supplier_name',
                'Exporter/Seller Country': 'supplier_country',
                'Exporter/Seller Contact': 'supplier_contact',
                'Exporter/Seller Address': 'supplier_address',
                'Exporter/Seller Email': 'supplier_email',
                'HS Code': 'hs_code',
                'HS Description':'hs_code_description',
                'Product Description':'commodity_description',
                'Weight':'weight',
                'Weight Unit':'weight_unit',
                'Quantity':'quantity',
                'Quantity Unit':'quantity_unit',
                'Amount':'amount',
                'Incoterms':'fob',
                'Vessel':'vessel',
                'Voyage':'voyage',
                'Country of Origin':'origin_country',
                'Address Of Destnation':'poa',
                'Country Of Destnation':'dest_country',
                'Address Of Origin':'poe',
                'Shipper':'supplier_name',
                'Means Of Transport':'trans_type',
                'Consignee':'importer_name',
                'Notifier':'notify_party_name',
                'Container ID':'container_id',
                'Container Type':'container_type',
                'Container Size':'container_size',
                'Container Desc Dode':'container_desc_code',
            },
            exportConfig: {
                fields: {
                    style: {
                        alignment: {
                            horizontal: 'center'
                        }
                    }
                },
                sheet: {
                    cell: {
                        style: {
                            alignment: {
                                horizontal: 'center',
                                vertical: 'center'
                            }
                        }
                    }
                }
            },
            DetailsForm: [],
            imexportsDialog: false,
            content: [],
            value: {},
            isExpanded:false,
            maxCharacters:200,
            exportLoding:false
        };
    },
    mounted() {
        window.addEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    computed: {
        limitedDescription() {
            const maxCharacters = 200;
            if (this.value && this.value?.commodity_description) {
                if (this.value.commodity_description.length > maxCharacters) {
                    return this.value?.commodity_description.substring(0, maxCharacters) + '...';
                }else{
                    return this.value?.commodity_description;
                }
            }
            return '';
        },

        rows() {
            const rows = [];
            let row = [];
            for (const item of this.content) {
                if (item.field) {
                    row.push(item);
                    if (row.length === 2) {
                        rows.push(row);
                        row = [];
                    }
                } else {
                    rows.push([item]);
                }
            }
            if (row.length > 0) {
                rows.push(row);
            }
            return rows;
        },
    },
    watch: {
        token: {
            handler(newVal, oldVal) {
                if (newVal) {
                    // this.openMenu = ['1', '2', '3', '4', '5'];
                    this.getConditions();
                    this.getPower();
                }
                // this.loadig=false
            },
            immediate: true,
        },
        countryList(val) {
            this.cascaderKey++;
        },
        industryList(val) {
            this.cascaderKey++;
        },
    },
    created() {
        //获取权限列表
        if (!this.token) this.getPower();
        // this.getPower();
        // this.getConditions();
    },
    destroyed() {
        // bus.$off("handleShowLogin")
    },
    methods: {
        //详情导出
        exportDetail() {

            const element = document.getElementById('exportDetail');
            const options = {
                filename: 'exportDetail.pdf',
                image: { type: 'jpeg', quality: 1 },
                // html2canvas: { scale:1 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            };
            html2pdf().from(element).set(options).save();
            // html2pdf().from(element).save();
        },
        toggleExpanded() {
            this.isExpanded = !this.isExpanded;  
        },

        // 点击展开进出口详情eldiog
        godetail(row) {
            let hasPower = this.powerResult[0].list[0].power;
            if (hasPower) {
                this.imexportsDialog = true;

                tradedetails({ id: row.data_id }).then(res => {
                    if (res && res.code) {
                        this.value = res.data;

                        this.value.container_size = this.value?.container_size == undefined ? '' : this.value?.container_size.split(',')[0];
                        this.value.container_type = this.value?.container_type == undefined ? '' : this.value?.container_type.split(',')[0];
                        this.value.container_desc_code = this.value?.container_desc_code == undefined ? '' : this.value?.container_desc_code.split(',')[0];
                    } else {
                        this.value = {};
                    }
                });
            } else {
                this.message();
            }
        },
        tradetodetail(row, country) {
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row + '&companyCountry=' + country + '&information=Search Trade',
            });

            window.open(routeData.href, '_blank');
        },
        zhankai() {
            let hasPower = this.powerResult[0].list[0].power;
            if (!hasPower) {
                this.openMenu = [];
                this.message();
            }
        },
        // 通过权限提示先选择产品
        aaaa() {
            let hasPower = this.powerResult[0].list[0].power;
            if (hasPower) {
                if (this.hscodeCheckList.length == 0) {
                    // this.openMenu = ['5'];
                    if (this.openMenu.length == 0) {
                        this.openMenu = ['5'];
                    }
                    this.tabDisabled2 = true;
                    this.$refs.input.classList.add('shake');
                    setTimeout(() => {
                        this.$refs.input.classList.remove('shake');
                    }, 500);
                } else {
                    let poewr = this.powerResult[1].power;
                    if (poewr) {
                        this.tabDisabled = false;
                    } else {
                        this.message();
                    }
                    this.tabDisabled2 = false;
                }
            } else {
                this.message();
            }
        },
        //  products筛选
        searchPersonByName() {
            let list = this.hscodeListtable;
            let keyWord = this.searchProductname;
            var arr = [];
            if (keyWord == '') {
                this.hscodeList = list;
            } else {
                arr = list.filter(item => {
                    return item.valueEn.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
                });
                this.hscodeList = arr;
            }
            //   this.onPageChange(1)
        },
        tabshow() {
            if (this.isActive) {
                this.isActive = false;
            } else {
                this.isActive = true;
            }
        },
        // 处理鼠标点击事件 登录权限
        handleonmousedown(e) {
            // 处理登录功能：
            // 未登录  允许点击【翻译
            const token = localStorage.getItem('token');
            if (!token) {
                if (!(e.target?._prevClass === 'top_ic_btn no_login' || e.target._prevClass === 'tbl_company1 line_11')) {
                    bus.$emit('handleShowLogin', true); //是否显示登录
                    // bus.$emit('headLogFlg', true); //是否显示登录
                }
            }
        },
        async getTrade() {
            this.loading = true;
            this.searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
            // this.searchCode = this.$route.query.companyCountry ? this.$route.query.companyCountry : this.countryCheck;

            // let hscodeCheck = this.hscode ? this.hscodeCheck.concat(this.hscode) : this.hscodeCheck;
            let hscodeCheck = this.hscode ? this.hscode : this.hscodeCheck;
            this.searchProduct = this.searchProduct == undefined ? '' : this.searchProduct;
            const params = 'page=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&countryCode=' + this.searchCode + '&keyword=' + this.searchName + '&hscode=' + hscodeCheck + '&buyName=' + this.buyName + '&supplerName=' + this.supplerName + '&hscodeDescription=' + this.searchProduct + '&market=' + this.marketCheck + '&exportOrImportType=' + this.importOrExportCheck + '&timePeriod=' + this.timePeriodCheck;
            await searchTrade(params).then(res => {
                if (res && res.code) {
                    this.page.total = Number(res.data.total);
                    this.searchResultsTable = res.data.rows;
                    this.loading = false;
                } else {
                    this.searchResultsTable = [];
                    this.loading = false;
                }
            });
        },
        // 跳详情
        setid(row, name) {
            let import_aaaid = row.import_aaaid;
            let export_aaaid = row.export_aaaid;
            let aaaid = name == 'Buyer' ? import_aaaid : export_aaaid;
            let countrycode = name == 'Buyer' ? row.import_country_code : row.export_country_code;
            sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + aaaid + '&companyCountry=' + countrycode + '&information=Search Trade',
            });
            window.open(routeData.href, '_blank');
        },

        setid1(row) {
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row.aaaid + '&companyCountry=' + row.countryCode + '&information=Search Trade',
            });

            window.open(routeData.href, '_blank');
        },
        // 跳转国家
        gotocountry(row) {
            let routeData = this.$router.resolve({
                path: '/country?' + '&companyCountry=' + row.countryCode,
            });

            window.open(routeData.href, '_blank');
        },
        gotocountry1(row, name) {
            let countrycode = name == 'import' ? row.import_country_code : row.export_country_code;
            let routeData = this.$router.resolve({
                path: '/country?' + '&companyCountry=' + countrycode,
            });

            window.open(routeData.href, '_blank');
        },
        gotocountry2(row) {
            let countrycode = row;
            let routeData = this.$router.resolve({
                path: '/country?' + '&companyCountry=' + countrycode,
            });

            window.open(routeData.href, '_blank');
        },
        //获取菜单
        async getConditions(value) {
            // return
            //hscodeDescription	否	聚合hscode枚举
            this.loadig = true;
            this.searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
            // this.searchCode = this.$route.query.companyCountry ? this.$route.query.companyCountry : this.countryCheck;

            // let hscodeCheck = this.hscode ? this.hscodeCheck.concat(this.hscode) : this.hscodeCheck;
            let hscodeCheck = this.hscode ? this.hscode : this.hscodeCheck;
            
            this.searchProduct = this.searchProduct == undefined ? '' : this.searchProduct;

            const params = 'countryCode=' + this.searchCode + '&keyword=' + this.searchName + '&hscode=' + hscodeCheck + '&buyName=' + this.buyName + '&supplerName=' + this.supplerName + '&hscodeDescription=' + this.searchProduct + '&market=' + this.marketCheck + '&exportOrImportType=' + this.importOrExportCheck + '&timePeriod=' + this.timePeriodCheck;
            await getFilterConditions(params).then(res => {
                if (res && res.code) {
                    this.loadig = false;
                    const data = res.data;
                    this.marketList = data.marketList;
                    this.countryList = data.countryList;
                    this.importOrExportList = data.importOrExportList;
                    this.timePeriodList = data.timePeriodList;
                    this.hscodeList = data.hscodeList;
                    // if(value==true){
                    //     this.hscodeCheckList=data.hscodeList[0]?.code?data.hscodeList[0]?.code:''
                    // }
                    //
                    this.hscodeListtable = data.hscodeList;
                    let list2 = this.countryList;
                    if (list2 && list2.length > 0) {
                        list2.map((item, index) => {
                            item.label = item.valueEn;
                            item.value = item.code;
                            item.count = item.dateCount;
                            if (item.companyProvince) {
                                item.children = item.companyProvince;
                                item.children.map(it => {
                                    it.label = it.valueEn;
                                    it.value = it.code;
                                    it.count = it.dateCount;
                                });
                            }
                        });
                    }
                } else {
                    this.loadig = false;
                }
            });
        },

        //权限
        async getPower() {
            if (this.token) {
                this.loadig = true;
            } else {
                this.loadig = false;
            }
            this.getPowerMenu().then(async () => {
                let hasPower = this.powerResult[0].power;
                // || (!hasPower && (await this.getOrdersAdd()))
                if (hasPower) {
                    this.getTrade();
                }
            });
        },
        //权限目录
        async getPowerMenu() {
            let params = 'type=3' + '&code=' + this.hscodeCheck;
            let res = await getCompanyDetailMenuList(params);
            if (res && res.code) {
                this.powerResult = res.data;
            }
        },
        /**
         *用户购买报告 "type": 17 ：buyers/suppliers
         */
        getOrdersAdd() {
            const type = 17;
            // this.searchCode = this.$route.query.companyCountry ? this.$route.query.companyCountry : this.countryCheck;
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
            //过滤出没扣点的产品
            let proList = this.hscodeTag;
            let hasProList = this.payProCodeList;
            let newArr = proList.filter(itemA => {
                return hasProList.every(itemB => {
                    return itemB.code !== itemA.code;
                });
            });
            newArr.forEach((item, index) => {
                let params = {
                    companyName: item.valueEn, //"贸易产品描述",
                    aaaId: item.code, //"贸易产品描述code",
                    companyCountry: this.searchCode, // 国家二位编码
                    method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                    methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                    type: type, //订单类型 //必须
                };
                return new Promise((resolve, reject) => {
                    ordersAdd(params).then(result => {
                        let data = result;
                        if (data.code) {
                            this.getPowerMenu();
                            //已扣点的产品coda码
                            this.payProCodeList.push(item);
                            this.$message({
                                message: data.msg,
                                type: 'success',
                                // duration: 5000,
                            });
                            resolve(true);
                        } else {
                            this.$message({
                                message: data.msg,
                                type: 'error',
                                // duration: 5000,
                            });
                            let that = this;
                            setTimeout(function () {
                                let routeData = that.$router.resolve({
                                    path: '/account/addCredit',
                                });

                                window.open(routeData.href, '_blank');
                            }, 2000);
                            resolve(false);
                        }
                    });
                });
            });
        },
        filterCasCountry(node, val) {
            if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
                return true;
            }
        },
        //二次查询
        async RetrievalResult() {
            let hasPower = this.powerResult[0].list[0].power;
            // (!hasPower && (await this.getOrdersAdd()))
            if (hasPower) {
                this.getTrade();
            } else {
                this.message();
            }
        },
        //搜索product
        getTradeByProduct() {
            if (this.searchProduct) {
                this.getConditions();
            } else {
                if (this.searchProduct == '') {
                    this.isNameSearch = false;
                }
            }
        },
        //菜单checkbox选择
        async menuCheck(val) {
            if (val === 'market') {
                let arr = [];
                this.marketCheckList.forEach(item => {
                    this.marketList.forEach(it => {
                        if (it.code == item) {
                            arr.push(it);
                        }
                    });
                });
                this.marketTag = arr;
                // this.marketCheck = this.marketCheckList.join('@@');
                this.marketCheck = this.marketCheckList;
            }

            if (val === 'country') {
                let arrCountry = [];
                let arrProv = [];
                let arrCountryTag = [];
                this.countryCheckList.forEach(item => {
                    if (!arrCountry.includes(item)) {
                        this.countryList.forEach(ite => {
                            if (ite.value == item) {
                                arrCountryTag.push(ite.label);
                            }
                        });

                        arrCountry.push(item);
                    }
                    // arrProv.push(item[1]);
                });
                // this.countryCheck = arrCountry.join('@@');
                // this.province = arrProv.join('@@');
                this.countryTag = arrCountryTag;
                this.countryCheck = arrCountry;
                this.province = arrProv;
            }
            if (val === 'importOrExport') {
                let arr = [];
                this.importOrExportCheckList.forEach(item => {
                    this.importOrExportList.forEach(it => {
                        if (it.code == item) {
                            arr.push(it);
                        }
                    });
                });
                this.importTag = arr;
                // this.importOrExportCheck = this.importOrExportCheckList.join('@@');
                this.importOrExportCheck = this.importOrExportCheckList;
            }
            if (val === 'timePeriod') {
                let arr = [];
                this.timePeriodCheckList.forEach(item => {
                    this.timePeriodList.forEach(it => {
                        if (it.code == item) {
                            arr.push(it);
                        }
                    });
                });
                this.timeTag = arr;
                // this.timePeriodCheck = this.timePeriodCheckList.join('@@');
                this.timePeriodCheck = this.timePeriodCheckList;
            }
            if (val === 'hscode') {
                let arr = [];
                let arrHs = [];
                this.hscodeCheckList.forEach(item => {
                    this.hscodeList.forEach(it => {
                        if (it.code == item) {
                            arr.push(it);
                            arrHs.push(it.valueEn);
                        }
                    });
                });
                // this.hscode=
                this.hscodeTag = arr;
                this.hscodeCheck = this.hscodeCheckList;
                // this.hscode = this.hscodeCheck[0];
                // this.searchProduct = arrHs[0];
                this.hscodeValEnCheck = arrHs;
                let poewr = this.powerResult[1].power;
                if (poewr) {
                    this.tabDisabled = false;
                }

                this.handleTabClick();
            }
            this.getConditions();
        },
        //清除标签
        handleClose(tag, name) {
            if (name === 'marketTag') {
                this.marketTag.splice(this.marketTag.indexOf(tag), 1);
                this.marketCheckList.splice(this.marketCheckList.indexOf(tag.code), 1);
                this.marketCheck = this.marketCheckList;
            }
     
            if (name === 'countryTag') {
                this.countryTag.splice(this.countryTag.indexOf(tag), 1);

                let tagcountry = '';

                this.countryCheckList.forEach((item, index) => {
                    this.countryList.forEach(ite => {
                        if (ite.valueEn == tag) {
                            tagcountry = ite.code;
                        }
                    });

                    if (item.includes(tagcountry)) {
                        this.countryCheckList.splice(index, 1);
                        this.countryCheck.splice(index, 1);
                    }
                });

                if (this.countryTag.length == 0) {
                    this.searchCode = '';
                    this.countryCheck = '';
                }
                // this.countryCheckList.splice(this.countryCheckList.indexOf(tag), 1);
            }
            if (name === 'importTag') {
                this.importTag.splice(this.importTag.indexOf(tag), 1);
                this.importOrExportCheckList.splice(this.importOrExportCheckList.indexOf(tag.code), 1);
                this.importOrExportCheck = this.importOrExportCheckList;
            }
            if (name === 'timeTag') {
                this.timeTag.splice(this.timeTag.indexOf(tag), 1);
                this.timePeriodCheckList.splice(this.timePeriodCheckList.indexOf(tag.code), 1);
                this.timePeriodCheck = this.timePeriodCheckList;
            }
            if (name === 'hscodeTag') {
                this.hscodeTag.splice(this.hscodeTag.indexOf(tag), 1);

                this.hscodeCheckList.splice(this.hscodeCheckList.indexOf(tag.code), 1);
                this.hscodeCheck = this.hscodeCheckList;
                this.searchProductname = '';
            }
            if (name === 'hsCode') {
                this.hscode = '';
            }
            if (name === 'buyName') {
                this.buyName = '';
            }
            if (name === 'supplerName') {
                this.supplerName = '';
            }
            if (name === 'searchProduct') {
                this.searchProduct = '';
            }
            this.handleTabClick();
            this.getConditions();
        },
        //清除所有标签
        clearAllTags() {
            this.searchProductname = '';
            this.marketTag = [];
            this.countryTag = [];
            this.importTag = [];
            this.timeTag = [];
            this.hscodeTag = [];

            this.marketCheckList = [];
            this.countryCheckList = [];
            this.timePeriodCheckList = [];
            this.importOrExportCheckList = [];
            this.hscodeCheckList = [];

            this.marketCheck = [];
            this.countryCheck = [];
            this.timePeriodCheck = [];
            this.importOrExportCheck = [];
            this.hscodeCheck = [];
            this.hscode = '';
            this.buyName = '';
            this.supplerName = '';
            this.searchProduct = '';

            this.getConditions();
        },
        //分页 title: "page"
        async onPageChange(pageNo) {
            let hasPower = this.powerResult[0].list[3].power;
            // || (!hasPower && (await this.getOrdersAdd()))
            if (hasPower) {
                this.page.pageNo = pageNo;
                this.getTrade();
            } else {
                this.message();
            }
        },
        currtentPageChange(pageSize) {
            let hasPower = this.powerResult[0].list[3].power;
            // || (!hasPower && (await this.getOrdersAdd()))
            if (hasPower) {
                this.page.pageSize = pageSize;
                this.getTrade();
            } else {
                this.message();
            }
        },
        //导出 title: "export"
        async exportCompany() {
            // || (!hasPower && (await this.getOrdersAdd()))
            let hasPower = this.powerResult[0].list[2].power;
            if (hasPower) {
                // this.DetailsForm = this.checkedCompanyList;
                this.DetailsForm = this.companyList;
            } else {
                this.message();
            }
        },
        //导出弹框
        async exportResult() {
            this.companyList = [];
            let hasPower = this.powerResult[0].list[2].power;
            // || (!hasPower && (await this.getOrdersAdd()))
            if (hasPower) {
                // this.pageNo = 0;
                // this.pageSize = 20;
                this.infiniteScroll();
                this.exportDialog = true;
            } else {
                this.message();
            }
        },
        // 滚动加载方法
        infiniteScroll() {
            // this.routeLoad = true;
            // this.pageNo += 1; // 页码每次滚动+1
            this.getRouteList();
        },
        // 获取数据
        async getRouteList() {
            this.exportLoding=true
            this.searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
            this.searchProduct = this.searchProduct == undefined ? '' : this.searchProduct;
            let hscodeCheck = this.hscode ? this.hscode : this.hscodeCheck;
         
            const params = 'page=1'  + '&pageSize=500' + '&countryCode=' + this.searchCode + '&keyword=' + this.searchName + '&hscode=' + hscodeCheck + '&buyName=' + this.buyName + '&supplerName=' + this.supplerName + '&hscodeDescription=' + this.searchProduct + '&market=' + this.marketCheck + '&exportOrImportType=' + this.importOrExportCheck + '&timePeriod=' + this.timePeriodCheck;
            await exportTradeDetail(params)
                .then(res => {
                    this.companyList= res.data;
                    this.companyList.map(item=>{
                        item.data=(item.imp_year==undefined?'':item.imp_year)+'-'+(item.imp_month==undefined?'':item.imp_month)+'-'+(item.imp_day==undefined?'':item.imp_day)
                        item.Source=(item.channel_no==undefined?'':item.channel_no)+'_'+(item.iesign=='i'?'IMPORT':'EXPORT')
                        item.importer_country=this.getCountry(item.importer_country)
                        item.origin_country=this.getCountry(item.origin_country)
                        item.supplier_country=this.getCountry(item.supplier_country)
                        if(item.fob_usd !=null){
                            item.amount=item.fob_usd
                        }else{
                            item.amount=item.fob_oc+item.other_currency
                        }
                    })
                    this.exportLoding=false
                    // for (let i = 0; i < list.length; i++) {
                    //     this.companyList.push(list[i]);
                    // }
                    // this.companyTblList = this.companyList;
                    // if (list.length < 10) {
                    //     this.noMore = true;
                    // }
                    // if (this.companyList.length === res.data.total) {
                    //     this.noMore = true;
                    // }
                    // this.routeLoad = false;
                })
                .catch(res => {
                    this.exportLoding=false
                });
        },
        //模糊查询
        hanldFilterName() {
            let list = this.companyTblList;
            let keyWord = this.filterName;
            var arr = [];
            if (keyWord == '') {
                return (this.companyList = list);
            } else {
                arr = list.filter(item => {
                    return item.hscode_description_inter ? item.hscode_description_inter.includes(keyWord) : '';
                });
                this.companyList = arr;
            }
        },
        //弹窗全选公司
        checkedAllCompany(val) {
            this.checkedCompanyList = val ? this.companyList : [];
        },
        //table 搜索
        searchProducts() {
            this.isNameSearch = !this.isNameSearch;
        },
        //tab切换
        async handleTabClick() {
            if (this.hscodeCheckList.length == 0) {
                this.tabDisabled2 = true;
            } else {
                this.tabDisabled2 = false;
            }
            this.marketBuyerX = [];
            this.marketBuyerList = [];
            this.marketSupplierX = [];
            this.marketSupplierList = [];
            this.marketTranX = [];
            this.marketTranList = [];
            this.counBuyerX = [];
            this.counBuyerList = [];
            this.counSupplierX = [];
            this.counSupplierList = [];
            this.counTranX = [];
            this.counTranList = [];

            this.SuppliersXList = [];
            this.SuppliersYList = [];

            this.BuyersXList = [];
            this.BuyersYList = [];
            if (this.hscodeCheckList.length) {
                if (this.tabActiveName == 'Markets') {
                    let hasPower = this.powerResult[1].power;
                    // || (!hasPower && (await this.getOrdersAdd()))
                    if (hasPower) {
                        getTradeAggre('productCodes=' + this.hscodeCheck).then(async res => {
                            this.marketTable = res.data.tradeMarketsList;
                            let list = this.marketTable;
                            list.forEach(item => {
                                this.marketBuyerX.push(item.date);
                                this.marketBuyerList.push(item.buyer);
                                this.marketSupplierList.push(item.supplier);
                                this.marketSupplierX.push(item.date);
                                this.marketTranList.push(item.transcations);
                                this.marketTranX.push(item.date);
                            });
                            this.tabMarketEcharts(this.$refs.marketBuyer, 'No. of Buyer', this.marketBuyerX, this.marketBuyerList);
                        });
                    }
                } else if (this.tabActiveName == 'Countries') {
                    let hasPower = this.powerResult[2].power;
                    // || (!hasPower && (await this.getOrdersAdd()))
                    if (hasPower) {
                        getTradeAggre('productCodes=' + this.hscodeCheck).then(async res => {
                            this.countryTable = res.data.tradeCountriesList;
                            let list = this.countryTable;
                            list.forEach(item => {
                                this.counBuyerX.push(item.countryEn);
                                this.counBuyerList.push(item.buyer);
                                this.counSupplierList.push(item.supplier);
                                this.counSupplierX.push(item.countryEn);
                                this.counTranX.push(item.countryEn);
                                this.counTranList.push(item.transcations);
                            });
                            this.tabMarketEcharts(this.$refs.countriesBuyer, 'No. of Buyer', this.counBuyerX, this.counBuyerList);
                        });
                    }
                } else if (this.tabActiveName == 'Buyers') {
                    // this.getPowerMenu();
                    
                    let hasPower = this.powerResult[3].power;
                    if (hasPower) {
                        getTradeAggre('productCodes=' + this.hscodeCheck).then(async res => {
                            this.buyTable = res.data.tradeBuyersList;
                            let list = this.buyTable;
                            list.forEach(item => {
                                this.BuyersXList.push(item.transcations);
                                this.BuyersYList.push(item.companyNameEn);
                            });
                            this.tabBuyersEcharts(this.$refs.Buyers, this.BuyersXList, this.BuyersYList);
                        });
                    }
                } else if (this.tabActiveName == 'Suppliers') {
                    // this.getPowerMenu();
                    let hasPower = this.powerResult[4].power;
                    if (hasPower) {
                        getTradeAggre('productCodes=' + this.hscodeCheck).then(async res => {
                            this.supplierTable = res.data.tradeSupplierList;
                            let list = this.supplierTable;
                            list.forEach(item => {
                                this.SuppliersXList.push(item.transcations);
                                this.SuppliersYList.push(item.companyNameEn);
                            });
                            this.tabBuyersEcharts(this.$refs.Suppliers, this.SuppliersXList, this.SuppliersYList);
                        });
                    }
                }
            } else {
                this.tabActiveName = 'SearchResults';
                this.tabDisabled = true;
            }
        },
        //切换market echart
        handleMarketClick() {
            if (this.marketActiveName == 'market_Buyer') {
                let myChart = this.$echarts.init(document.getElementById('marketBuyer'));
                myChart.dispose();
                this.tabMarketEcharts(this.$refs.marketBuyer, 'No. of Buyer', this.marketBuyerX, this.marketBuyerList);
            } else if (this.marketActiveName == 'market_Supplier') {
                let myChart = this.$echarts.init(document.getElementById('marketSupplier'));
                myChart.dispose();
                this.tabMarketEcharts(this.$refs.marketSupplier, 'No. of Supplier', this.marketSupplierX, this.marketSupplierList);
            } else {
                let myChart = this.$echarts.init(document.getElementById('marketTransaction'));
                myChart.dispose();
                this.tabMarketEcharts(this.$refs.marketTransaction, 'Transaction', this.marketTranX, this.marketTranList);
            }
        },
        //切换Countries echart
        handleCountriesClick() {
            if (this.countryActiveName == 'countries_Buyer') {
                let myChart = this.$echarts.init(document.getElementById('countriesBuyer'));
                myChart.dispose();
                this.tabMarketEcharts(this.$refs.countriesBuyer, 'No. of Buyer', this.counBuyerX, this.counBuyerList);
            } else if (this.countryActiveName == 'countries_Supplier') {
                let myChart = this.$echarts.init(document.getElementById('countriesSupplier'));
                myChart.dispose();
                this.tabMarketEcharts(this.$refs.countriesSupplier, 'No. of Supplier', this.counSupplierX, this.counSupplierList);
            } else {
                this.tabMarketEcharts(this.$refs.countriesTransaction, 'Transaction', this.counTranX, this.counTranList);
            }
        },

        //market and countries echarts
        tabMarketEcharts(obj, titleName, xList, yList) {
            this.echartsBox = this.$echarts.init(obj);
            let that = this;
            const option = {
                tooltip: {
                    borderWidth: 1,
                    borderColor: '#ccc',
                    backgroundColor: '#fff',
                    textStyle: {
                        color: '#022955',
                        fontSize: 14,
                    },
                    formatter: function (params) {
                        params['value'] = that.filter(params['value']);
                        return params['seriesName'] + '<br/>' + params['name'] + '<br/>' + params['value'];
                    },
                },
                grid: {
                    left: '60',
                    right: '60',
                    bottom: '60',
                },
                xAxis: {
                    data: xList,
                    axisLabel: {
                        interval: 0,
                        rotate: 0,
                        formatter: function (value) {
                            if (value.length > 8) {
                                // 设置超过5个字符的横坐标隐藏
                                return value.substring(0, 8) + '...';
                            } else {
                                return value;
                            }
                        },
                        textStyle: {
                            color: '#8497AB',
                            fontSize: 10,
                        },
                   
                    },

                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#8497AB',
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#8497AB',
                            fontSize: 10,
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#8497AB',
                        },
                    },
                    axisTick: {
                        show: true,
                        length: 4,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: titleName,
                        type: 'bar',
                        barWidth: '20',
                        itemStyle: { color: '#8497AB' },
                        emphasis: {
                            itemStyle: {
                                color: '#FF7600',
                            },
                        },
                        data: yList,
                    },
                ],
            };
            this.echartsBox.setOption(option);
        },

        // Buyers and Suppliers echarts
        tabBuyersEcharts(obj, xList, yList) {
       
            this.echartsBox = this.$echarts.init(obj);
            let that = this;
            const option = {
                tooltip: {
                    backgroundColor: '#fff',
                    borderColor: '#ccc',
                    borderWidth: 1,
                    textStyle: {
                        color: '#022955',
                        fontSize: 14,
                    },
                    formatter: function (params) {
                        params['value'] = that.filter(params['value']);
                        return params['name'] + '<br/>' + params['value'];
                    },
                },
                grid: {
                    left: '19%',
                    // right: '4%',
                    // containLabel: true
                },
                xAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#8497AB',
                            fontSize: 10,
                        },
                    },
                },
                yAxis: {
                    type: 'category',
                    axisLabel: {
                        textStyle: {
                            color: '#8497AB',
                            fontSize: 12,
                            width: 71,
                        },
                   
                        formatter: function (params) {
                            var newParamsName = '';
                            var paramsNameNumber = params.length; // 实际标签的个数
                            var provideNumber = 11; //每行能显示的字的个数
                            var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整

                            // 条件等同于rowNumber>1
                            if (paramsNameNumber > provideNumber) {
                                /** 循环每一行,p表示行 */
                                for (var p = 0; p < rowNumber; p++) {
                                    var tempStr = '';
                                    var start = p * provideNumber;
                                    var end = start + provideNumber;
                                    if (p == 1) {
                                        tempStr = params.substring(start, end - 4) + '...';
                                        newParamsName += tempStr;

                                        return newParamsName;
                                    } else {
                                        // 此处特殊处理最后一行的索引值
                                        if (p == rowNumber - 1) {
                                            // 最后一次不换行
                                            tempStr = params.substring(start, paramsNameNumber);
                                        } else {
                                            // 每一次拼接字符串并换行
                                            tempStr = params.substring(start, end) + '\n';
                                        }
                                        newParamsName += tempStr;
                                    }
                                }
                            } else {
                                newParamsName = params;
                            }
                            return newParamsName;
                        },
                    },
                       inverse: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#8497AB',
                        },
                    },
                    axisTick: {
                        show: true,
                        length: 4,
                    },
                    data: yList,
                },
                series: [
                    {
                        type: 'bar',
                        barWidth: '28',
                        itemStyle: { color: '#8497AB' },
                        emphasis: {
                            itemStyle: {
                                color: '#FF7600',
                            },
                        },
                        data: xList,
                    },
                ],
            };
     
            this.echartsBox.setOption(option);
        },
    },
};
</script>
<style scoped>
.search_List_title {
    font-size: 16px;
    font-family: 'Arial Bold';
    line-height: 18px;
    margin: 20px 0;
}
.tab_box /deep/ .el-tabs__header {
    margin-bottom: 10px;
}
.el-tabs--card > .el-tabs__header {
    border: none;
}
.tab_box /deep/ .el-tabs__header,
.tab_box /deep/.el-tabs__content {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}
.tab_box /deep/ .el-tabs__header {
    padding: 11px 75px;
}
.result_tab {
    padding: 20px;
}
.tab_box /deep/ .el-tabs__nav-wrap {
    width: 100%;
    height: 34px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 4px 75px;
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-tabs__item {
    padding: 0 15px;
    width: 128px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    color: #8497ab;
    border: none;
    text-align: center;
}
.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}
.tab_box /deep/ .el-tabs__item.is-active {
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-family: 'Arial Bold';
    color: #ffffff;
}
.market_box {
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-table--scrollable-x .el-table__body-wrapper {
    overflow: hidden;
}
.tab_box /deep/ .el-table__body,
.tab_box /deep/ .el-table__footer,
.tab_box /deep/ .el-table__header {
    width: auto !important;
}
.table_market_box {
    width: 580px;
    max-height: 640px;
    overflow: auto;
}
.table_market_box /deep/ .el-table--border,
.table_market_box /deep/ .el-table--group {
    border-radius: 14px;
    border: 1px solid #ebeef5;
}
.table_market_box /deep/ .el-table--border::after,
.table_market_box /deep/ .el-table--group::after,
.table_market_box /deep/ .el-table::before {
    background-color: transparent;
}
.table_market_box /deep/ .el-table tbody tr:last-child td,
.table_market_box /deep/ .el-table th.is-leaf {
    border-width: 0;
}
.table_market_box /deep/ .el-table th > .cell {
    padding-left: 5px;
    padding-right: 5px;
}
.market_tbl {
    padding: 20px 10px 20px 20px;
}
.echart_box {
    width: 600px;
    background: #f2f5f9;
    border: 1px solid #e7eaed;
    padding: 20px 20px 20px 10px;
}
.echart_tab /deep/ .el-tabs__header,
.echart_tab /deep/ .el-tabs__content {
    border-radius: 0;
    box-shadow: none;
}
.echart_tab /deep/ .el-tabs__header {
    border-bottom: none;
    padding: 10px;
}
.echart_tab /deep/ .el-tabs__nav {
    padding: 2px 100px;
    justify-content: space-evenly;
}
.echart_tab /deep/ .el-tabs__nav-wrap {
    height: 34px;
    background-color: #ffffff;
    border: none;
}
.echart_tab /deep/ .el-tabs__item:first-child {
    border-left: 1px solid #e7eaed;
}
.echart_tab /deep/ .el-tabs__item {
    /* width: 90px; */
    height: 30px;
    line-height: 30px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    margin: 0 5px;
}
.echart_tab /deep/ .el-tabs__item.is-active {
    border: 1px solid #ff7600;
    border-radius: 8px;
    background: #ffffff;
    font-family: 'Arial';
    color: #ff7600;
    box-shadow: none;
}
.echart_cont {
    width: 570px;
    height: 460px;
    overflow: hidden;
}

.table_box {
    position: relative;
}
.table_add img {
    width: 14px;
    margin-left: 4px;
}
.tbl_search_box {
    position: absolute;
    left: 140px;
}
.tbl_search {
    position: relative;
    width: 260px;
    height: 40px;
    z-index: 9;
}
.tbl_search input {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
    border-radius: 8px;
    border: 1px solid #1290c9;
    padding: 0 40px 0 20px;
}
.tbl_search img {
    position: absolute;
    width: 18px;
    right: 20px;
    top: 12px;
}
.company_box .el-checkbox {
    width: 50%;
    margin-bottom: 20px;
    margin-right: 0;
}
.company_box .comp_top_check .el-checkbox {
    margin-bottom: 10px;
    margin-left: 20px;
}
.company_box /deep/ .el-checkbox__label {
    width: 420px;
    overflow: hidden;
    color: #022955;
    text-overflow: ellipsis;
    line-height: 10px;
}
.company_box /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #022955;
}
.company_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.company_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #022955;
    border-color: #022955;
}
.company_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #8497ab;
}
.company_box /deep/ .el-checkbox__inner::after {
    height: 10px;
    left: 6px;
    top: 1px;
    width: 5px;
    border-width: 2px;
}
.comp_search {
    position: relative;
    width: 300px;
    margin-bottom: 10px;
}
.comp_search .coll_search_btn {
    top: 7px;
}
.company_b_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
}
.btn_comp_box {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.check_item {
    position: relative;
    max-height: 540px;
    overflow: auto;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    padding: 20px 20px 0;
    margin-bottom: 10px;
}
.condition_lab {
    display: flex;
    flex-wrap: wrap;
}
.condition_labb {
    float: left;
    max-width: 888px;
}
.condition_labb /deep/ .el-tag {
    position: relative;
    background-color: #ffffff;
    border-color: #e7eaed;
    height: 40px;
    line-height: 20px;
    padding: 10px;
    color: #022955;
    border-radius: 12px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    max-width: 300px;
    padding-right: 30px;
    white-space: nowrap; /* 防止换行 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 显示省略号 */
}
.condition_labb /deep/ .el-tag .el-tag__close {
    color: #f56b6b;
    font-weight: 600;
    position: absolute;
    top: 14px;
    right: 5px;
}
.condition_labb /deep/ .el-tag .el-tag__close:hover {
    color: #f56b6b;
    background-color: #fff;
}
.cas_loc_box .casca_lab {
    padding-top: 0 !important;
    width: 160px;
    /* padding-top: 10px; */
}
.tbl_search /deep/ .el-input .el-input__inner {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
    border-radius: 8px;
    border: 1px solid #1290c9;
    padding: 0 40px 0 20px;
}
.tbl_search .el-input {
    height: 100%;
}
.tbl_search /deep/ .el-input .el-input__suffix {
    right: 40px;
}
.tbl_search img {
    position: absolute;
    width: 18px;
    right: 20px;
    top: 12px;
}
.Products /deep/ .el-input__inner {
    border-radius: 8px;
    background-color: #f2f5f9;
    border-color: #e7eaed;
    line-height: 34px;
    height: 34px !important;
    border: 1px solid red;
    transition: border-color 0.3s ease-in-out;
}
.hedr-icon-ydong {
    position: absolute;
    left: 215px;
    font-weight: 600;
}
/* .left_menu /deep/ .el-collapse-item__arrow{
display: none;
} */
.left_menu /deep/ .el-collapse-item__header {
    position: relative;
}
.shake {
    animation: shake 0.5s;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
    }
}
.table_oper1 {
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 27px;
}
.ant-modal-body {
    padding: 24px;
    font-size: 14px;
    color: #333333;
    line-height: 1.5;
    word-wrap: break-word;
}
.trade-details-field {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
}
.trade-details-modal table {
    width: 100%;
    margin-bottom: 18px;
    font-size: 12px;
}
.trade-details-modal table,
.trade-details-modal td,
.trade-details-modal th {
    /* border: 1px solid #ddd; */
    padding: 0.55rem;
    border-collapse: collapse;
    word-break: keep-all;
    text-align: right;
}
.trade-details-modal table thead {
    background: #1290c9;
    color: #fff;
}
.trade-details-modal table thead tr > th {
    /* color: #000; */
    font-weight: 700;
    padding-left: 12px;
    text-align: left;
}
.trade-details-modal th {
    /* border: 1px solid #ddd; */
    padding: 0.55rem;
}
.button-list-in-trade-detail {
    display: inline-block;
}
.button-list-in-trade-detail .row-content {
    display: inline-block;
    padding-right: 40px;
    vertical-align: top;
}
.button-list-in-trade-detail .link-button {
    display: inline-block;
    padding: 0 9px;
    color: #177dff;
    cursor: pointer;
    border-radius: 2px;
    transition: all 0.25s;
}
.trade-details-modal table tbody tr > td {
    color: rgba(0, 0, 0, 0.65);
    padding-left: 12px;
    text-align: left;
    line-height: 25px;
    /* word-break: break-all; */
}
.trade-details-modal td {
    /* border: 1px solid #ddd; */
    padding: 0.55rem;
}
.button-list-in-trade-detail .ant-divider-vertical {
    margin: 0 3px;
}
.ant-divider,
.ant-divider-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    width: 1px;
    height: 0.9em;
    margin: 0 8px;
    vertical-align: middle;
}
.ant-divider {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum';
    background: #e8e8e8;
}
.line_11 {
    color: rgb(18, 144, 201);
    text-decoration: underline;
    cursor: pointer;
}
.back {
    background-color: #f1f3f4;
}
.trade-details-modal tr {
    border: 1px solid #ddd;
    border-right: none;
    border-left: none;
}
.companyimg {
    float: left;
    width: 36px;
    height: 26px;
    flex: none;
    margin-right: 4px;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
}
.company-head{
    /* line-height: 35px; */
    font-size: 16px;
    color: #333333;
    margin-bottom: 10px;
}
.company-body{
 width: 100%;
 display: flex;
 justify-content: space-between;
 margin-bottom: 10px;
 flex-wrap: wrap;
 margin-left:15px;
}
.company-item{
    width: 48%;
    line-height: 35px;
    display: flex;
    align-items: center;
     color: #333333;
     flex-wrap: wrap;
}
.company-item span{
    margin-left: 20px;
}
.company-item .el-tag {
 
    border:none;
    margin: 0;
    /* padding: 0; */
    padding-right:0px;
}
</style>
